.dropdown {
  position: relative;
  display: inline-flex;
}

.button {
  background-color:#EEF3FF;
  border-radius: 50%;
  outline: none;
  user-select: none;
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  transition: filter 160ms ease-out;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 1em;
}

.button:hover {
  filter: brightness(0.9);
}

.active {
  filter: brightness(0.9);
}

.disabled {
  cursor: not-allowed;
}

.disabled:hover {
  filter: brightness(1) !important;
}

.menu {
  position: absolute;
  top: 30px;
  left: 60px;
  z-index: 1000;
  flex-direction: column;
  width: 75px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.2);
}

.menu > ul {
  list-style: none;
  padding-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.menuRight {
  left: 0;
}
