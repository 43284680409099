.col {
    width: 10%;
}

.sidenav {
    background-color: rgb(240, 240, 240);
}

.chord {
    padding-top: 5px;
    padding-bottom: 5px;
}

.col1 {
    width: 25%;
}

.col2 {
    width: 25%;
}

.col3 {
    width: 50%;
}

h2 {
    font-size: 1.75em;
}

.title {
    border-bottom: 2px solid black;
}
