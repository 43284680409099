.nav {
    background-color: #80dfff;
}

label {
    font-size: 1.5em;
    font-weight: bold;
}

.dropdown {
    display: flex;
    justify-content: space-between;
    height: 15%;
}

h2 {
    padding-right: 10px;
}

p {
    font-size: 30px;
}

.keys {
    border-top: 2px solid black;
}

.key:hover {
    background-color: #b1f4fa;
    border-radius: 20px;
}

.active {
    border: 5px solid rgb(7, 71, 167);
    border-radius: 20px;
}

.button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.major {
    margin-right: 200px;
}

.major, .minor {
    padding-left: 10px;
    padding-right: 10px;
}

.major:hover, .minor:hover {
    background-color: #b1f4fa;
    border-radius: 20px;
}