.header {
    border-bottom: 2px solid black;
}

.tuningButton {
    width: 100px;
    height: 75px;
    border-radius: 20% !important;
}

.tuningButtonDiv {
    padding-right: 10px;
    padding-left: 10px;
}

.tuningButtonActive {
    background-color: rgb(0, 123, 255) !important;
    color: white !important;
}

.presets {
    border-bottom: 2px solid black;
}

.tuningRow {
    padding-top: 20px;
    padding-bottom: 20px;
}

.halfstep {
    height: 50px !important;
    width: 50px !important;
    margin: auto;
}